import React, { useState, useEffect, useLayoutEffect } from "react";
import {
  View,
  Text,
  TextInput,
  ScrollView,
  TouchableOpacity,
  Image,
} from "react-native";
import { auth, db } from "../firebase/firebaseConfig";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
} from "@firebase/auth";
import {
  doc,
  getDoc,
  setDoc,
  updateDoc,
  serverTimestamp,
} from "@firebase/firestore"; // Added updateDoc here
import { useNavigation, useRoute } from "@react-navigation/native";
import {
  Provider as PaperProvider,
  Dialog,
  Portal,
  Button,
  Paragraph,
} from "react-native-paper";
import { getErrorMessage } from "../utils/errorMessages";
import {
  validateEmail,
  validateName,
  validatePhoneNumber,
  validatePassword,
} from "../utils/validation";
import Loading from "../components/Loading";
import styles from "../styles/styles";
import PhoneInputWithCountrySelect from "react-phone-number-input";
import "react-phone-number-input/style.css";

const AuthScreen = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [surname, setSurname] = useState("");
  const [mobile, setMobile] = useState("");
  const [isLogin, setIsLogin] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [errors, setErrors] = useState({});
  const navigation = useNavigation();
  const route = useRoute();
  const MIN_PHONE_NUMBER_LENGTH = 10;

  // Determine initial mode
  const initialMode = route.params?.mode === "signUp" ? false : true;
  useEffect(() => {
    setIsLogin(initialMode);
  }, [initialMode]);

  useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: () => (
        <TouchableOpacity
          onPress={() => navigation.navigate("Home")}
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Image
            source={require("../assets/icon.png")}
            style={styles.headerLogo}
            resizeMode="contain"
          />
          <Text style={styles.navBarText}>Return Home</Text>
        </TouchableOpacity>
      ),
      headerTitle: () => <></>,
      headerRight: null,
      headerStyle: {
        backgroundColor: "#161C24",
      },
      headerTintColor: "#fff",
    });
  }, [navigation]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        // Update user activity when the user logs in
        try {
          const userRef = doc(db, "Users", user.uid);
          await updateDoc(userRef, {
            last_active: serverTimestamp(),
          });
          console.log("User activity updated");
        } catch (error) {
          console.error("Error updating user activity:", error);
        }

        const docRef = doc(db, "Users", user.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const userData = docSnap.data();
          if (userData.privacyPolicyAccepted && userData.termsAccepted) {
            navigation.navigate("UserInfo");
          } else {
            navigation.navigate("Agreement");
          }
        } else {
          navigation.navigate("Agreement");
        }
      }
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, [auth, navigation]);

  const validateInputs = () => {
    let valid = true;
    let errors = {};

    if (!validateEmail(email)) {
      errors.email = "Invalid email address";
      valid = false;
    }
    if (!validatePassword(password)) {
      errors.password = "Password must be at least 6 characters long.";
      valid = false;
    }
    if (!isLogin && password !== confirmPassword) {
      errors.confirmPassword = "Passwords do not match";
      valid = false;
    }
    if (!isLogin && !validatePhoneNumber(mobile, MIN_PHONE_NUMBER_LENGTH)) {
      errors.mobile = "Invalid Phone Number.";
      valid = false;
    }
    if (!isLogin && !validateName(firstName)) {
      errors.firstName = "Invalid first name.";
      valid = false;
    }
    if (!isLogin && !validateName(surname)) {
      errors.surname = "Invalid last name.";
      valid = false;
    }

    setErrors(errors);
    return valid;
  };

  const handleAuthentication = async () => {
    setIsLoading(true);
    setErrors({});

    if (!validateInputs()) {
      setIsLoading(false);
      return;
    }

    try {
      if (isLogin) {
        const userCredential = await signInWithEmailAndPassword(
          auth,
          email,
          password
        );
        const userRef = doc(db, "Users", userCredential.user.uid);
        await updateDoc(userRef, {
          last_active: serverTimestamp(),
        });
        console.log("User activity updated");
      } else {
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );
        await setDoc(doc(db, "Users", userCredential.user.uid), {
          firstName,
          surname,
          mobile,
          email: userCredential.user.email,
          privacyPolicyAccepted: false,
          termsAccepted: false,
          verificationStarted: false,
          last_active: serverTimestamp(),
        });
      }
    } catch (error) {
      const errorMessage = getErrorMessage(error.code);
      setDialogMessage(errorMessage);
      setDialogVisible(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PaperProvider>
      <View style={{ flex: 1 }}>
        {isLoading && <Loading />}
        <ScrollView contentContainerStyle={styles.container}>
          <View style={styles.authContainer}>
            <Text style={styles.title}>{isLogin ? "Sign In" : "Sign Up"}</Text>
            {!isLogin && (
              <>
                <TextInput
                  style={styles.input}
                  value={firstName}
                  onChangeText={setFirstName}
                  placeholder="First Name"
                  placeholderTextColor="#B0B0B0"
                />
                {errors.firstName && (
                  <Text style={styles.errorText}>{errors.firstName}</Text>
                )}
                <TextInput
                  style={styles.input}
                  value={surname}
                  onChangeText={setSurname}
                  placeholder="Surname"
                  placeholderTextColor="#B0B0B0"
                />
                {errors.surname && (
                  <Text style={styles.errorText}>{errors.surname}</Text>
                )}
                <View style={styles.phoneInputContainer}>
                  <PhoneInputWithCountrySelect
                    defaultCountry="US"
                    style={styles.phoneInputText}
                    value={mobile}
                    onChange={setMobile}
                    placeholder="Phone Number"
                  />
                </View>
                {errors.mobile && (
                  <Text style={styles.errorText}>{errors.mobile}</Text>
                )}
              </>
            )}
            <TextInput
              style={styles.input}
              value={email}
              onChangeText={setEmail}
              placeholder="Email"
              placeholderTextColor="#B0B0B0"
              autoCapitalize="none"
            />
            {errors.email && (
              <Text style={styles.errorText}>{errors.email}</Text>
            )}
            <TextInput
              style={styles.input}
              value={password}
              onChangeText={setPassword}
              placeholder="Password"
              placeholderTextColor="#B0B0B0"
              secureTextEntry
            />
            {errors.password && (
              <Text style={styles.errorText}>{errors.password}</Text>
            )}
            {!isLogin && (
              <TextInput
                style={styles.input}
                value={confirmPassword}
                onChangeText={setConfirmPassword}
                placeholder="Confirm Password"
                placeholderTextColor="#B0B0B0"
                secureTextEntry
              />
            )}
            {errors.confirmPassword && (
              <Text style={styles.errorText}>{errors.confirmPassword}</Text>
            )}
            <TouchableOpacity
              style={styles.buttonContainer}
              onPress={handleAuthentication}
            >
              <Text style={styles.buttonText}>
                {isLogin ? "Sign In" : "Sign Up"}
              </Text>
            </TouchableOpacity>
            <View style={styles.bottomContainer}>
              <Text
                style={styles.toggleText}
                onPress={() => setIsLogin(!isLogin)}
              >
                {isLogin
                  ? "Need an account? Sign Up"
                  : "Already have an account? Sign In"}
              </Text>
            </View>
          </View>
        </ScrollView>
        <View style={styles.footer}>
          <Image
            source={require("../assets/xgc_logo@0.75x.png")}
            style={styles.footerLogo}
          />
        </View>
        {/* Dialogue box for auth errors */}
        <Portal>
          <Dialog
            visible={dialogVisible}
            onDismiss={() => setDialogVisible(false)}
            style={styles.errorDialog}
          >
            <Dialog.Title style={{ color: '#FFFFFF' }}>Authentication Error</Dialog.Title>
            <Dialog.Content>
              <Paragraph style={styles.errorText}>{dialogMessage}</Paragraph>
            </Dialog.Content>
            <Dialog.Actions>
              <Button onPress={() => setDialogVisible(false)} labelStyle={{ color: 'white' }}>OK</Button>
            </Dialog.Actions>
          </Dialog>
        </Portal>
      </View>
    </PaperProvider>
  );
};

export default AuthScreen;
