export const validateEmail = (email) => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(email);
};

export const validatePassword = (password) => {
  return password.length >= 6; 
};

export const validatePhoneNumber = (phoneNumber, minLength = 10) => {
  if (!phoneNumber) {
    return false;
  }
  return phoneNumber.length >= minLength;
};

export const validateName = (name) => {
  if(!name) {
    return false;
  }
  return true;
};
