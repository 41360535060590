import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#161C24",
    padding: 20, // Increased padding
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  logo: {
    width: 150,
    height: 150,
    resizeMode: "contain",
    alignSelf: "center",
    marginVertical: 20,
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    color: "#FFFFFF",
    textAlign: "center",
    marginBottom: 20,
  },
  subtitle: {
    fontSize: 20,
    marginBottom: 20, // Increased space below subtitle
    textAlign: "center",
    color: "#FFFFFF",
  },
  description: {
    fontSize: 16,
    marginBottom: 30, // Increased margin for more space
    textAlign: "center",
    paddingHorizontal: 20,
    color: "#B0B0B0",
  },
  button: {
    backgroundColor: "#28a745",
    padding: 15,
    borderRadius: 10,
    marginVertical: 10, // Added vertical margin for spacing
    alignSelf: "center",
    width: 200,
  },
  paymentButton: {
    backgroundColor: "#0B67BC",
    padding: 15,
    borderRadius: 10,
    marginVertical: 10, // Added vertical margin for spacing
    alignSelf: "center",
    width: 200,
  },
  buttonDisabled: {
    backgroundColor: "#B0B0B0", // Grey color for disabled state
  },
  buttonInactive: {
    backgroundColor: "#B0B0B0", // Grey color for inactive button
    borderColor: "#B0B0B0",
  },
  buttonText: {
    fontSize: 16,
    color: "#FFFFFF",
    textAlign: "center",
  },
  infoSection: {
    backgroundColor: "#212B36",
    padding: 20,
    borderRadius: 10,
    marginVertical: 20, // Increased space between sections
    borderColor: "#26313D",
    borderWidth: 1,
    alignSelf: "center",
    width: "90%",
    maxWidth: 600,
  },
  infoTitle: {
    fontSize: 18,
    fontWeight: "600",
    color: "#FFFFFF",
    marginBottom: 10, // Increased space below title
  },
  infoText: {
    fontSize: 16,
    color: "#B0B0B0",
    marginBottom: 20, // Increased space below text
  },
  checkboxContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 10, // Increased margin between checkboxes
    width: "100%",
  },
  checkbox: {
    width: 24,
    height: 24,
    borderWidth: 2,
    borderColor: "#B0B0B0",
    backgroundColor: "#26313D",
    alignItems: "center",
    justifyContent: "center",
  },
  checkboxChecked: {
    width: 12,
    height: 12,
    backgroundColor: "#0B67BC",
  },
  label: {
    marginLeft: 10,
    color: "#FFFFFF",
  },
  switch: {
    marginLeft: 10,
  },
  switchContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginVertical: 20, // Increased margin for spacing
  },
  statusIndicator: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 15, // Increased margin
  },
  statusText: {
    fontSize: 16,
    color: "#FFFFFF",
    marginLeft: 10,
  },
  navBarLogo: {
    //Currently UNUSED in the project
    width: 200,
    height: 200,
    resizeMode: "contain",
    backgroundColor: "transparent",
  },
  navBarIcon: {
    width: 30,
    height: 30,
    resizeMode: "contain",
  },
  navBarButton: {
    padding: 10,
  },
  navBarText: {
    color: "#FFFFFF",
    fontSize: 12,
    margin: 20,
  },
  radioGroupContainer: {
    marginVertical: 20,
    alignItems: "center",
  },
  radioGroup: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginVertical: 10,
  },
  radioButton: {
    marginHorizontal: 10,
  },
  radioButtonLabel: {
    color: "#FFFFFF",
  },
  footerLogo: {
    width: 100,
    height: 100,
    resizeMode: "contain",
    alignSelf: "center",
    marginTop: 20,
  },
  footer: {
    backgroundColor: "#161C24",
    padding: 20,
    alignItems: "center",
  },
  footerText: {
    color: "#B0B0B0",
    fontSize: 16,
  },
  input: {
    backgroundColor: "#26313D",
    color: "#FFFFFF",
    padding: 15,
    marginBottom: 20,
    borderRadius: 5,
    borderColor: "#3498db",
    borderWidth: 1,
  },
  inputContainer: {
    marginBottom: 20,
  },
  authContainer: {
    backgroundColor: "#212B36",
    padding: 20,
    borderRadius: 10,
    borderColor: "#26313D",
    borderWidth: 1,
    width: "80%",
    maxWidth: 500,
    alignSelf: "center",
    marginVertical: 20,
  },
  link: {
    color: "#0B67BC",
    fontSize: 16,
    textDecorationLine: "underline",
  },
  linkContainer: {
    marginTop: 10,
    marginBottom: 20,
    alignItems: "center",
  },
  headerTitleContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  headerRightButton: {
    marginRight: 10,
  },
  headerLogo: {
    width: 200,
    height: 80,
    resizeMode: "contain",
  },
  sectionContainer: {
    marginTop: 32,
    paddingHorizontal: 24,
  },
  sectionTitle: {
    fontSize: 24,
    fontWeight: "600",
    color: "#ffffff",
  },
  sectionDescription: {
    marginTop: 8,
    fontSize: 18,
    fontWeight: "400",
    color: "#b0b0b0",
  },
  highlight: {
    fontWeight: "700",
  },
  bottomContainer: {
    alignItems: "center",
  },
  toggleText: {
    color: "#3498db",
    marginTop: 10,
  },
  scrollView: {
    flex: 1,
    justifyContent: "center",
  },
  scrollViewContent: {
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 20,
  },
  agreementTitle: {
    fontSize: 22,
    fontWeight: "bold",
    color: "#FFFFFF",
    textAlign: "center",
    marginBottom: 20,
  },
  agreementContainer: {
    backgroundColor: "#212B36",
    padding: 20,
    borderRadius: 10,
    marginBottom: 20,
    borderColor: "#26313D",
    borderWidth: 1,
    width: "90%",
    maxWidth: 600,
    alignSelf: "center",
  },
  agreementText: {
    fontSize: 16,
    color: "#B0B0B0",
    marginBottom: 15,
  },
  agreeButton: {
    backgroundColor: "#0B67BC",
    padding: 15,
    borderRadius: 10,
    marginBottom: 10,
    alignSelf: "center",
    width: 200,
  },
  disagreeButton: {
    backgroundColor: "#e74c3c",
    padding: 15,
    borderRadius: 10,
    marginBottom: 20,
    alignSelf: "center",
    width: 200,
  },
  countryPickerButton: {
    backgroundColor: "#26313D",
    borderColor: "#3498db",
    borderWidth: 1,
    borderRadius: 5,
    padding: 15,
    marginBottom: 20, // Ensured sufficient gap
  },
  countryPickerButtonText: {
    color: "#FFFFFF",
    fontSize: 16,
  },
  countryPickerContainer: {
    backgroundColor: "#212B36",
    borderRadius: 10,
    padding: 10,
  },
  countryPickerItemText: {
    color: "#FFFFFF",
    fontSize: 16,
    padding: 10,
  },
  phoneContainer: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#26313D",
    borderRadius: 5,
    borderColor: "#3498db",
    borderWidth: 1,
    marginBottom: 20,
    paddingHorizontal: 10,
  },
  phoneInputContainer: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#26313D",
    borderRadius: 5,
    borderColor: "#3498db",
    borderWidth: 1,
    padding: 15, // Ensure proper padding
    marginBottom: 20,
  },
  phoneCodeText: {
    color: "#FFFFFF", // Ensuring consistent text color
    fontSize: 16,
    padding: 10,
  },
  phoneNumberInput: {
    flex: 1,
    color: "#FFFFFF",
    fontSize: 16,
    padding: 10,
    marginLeft: 10,
    textAlign: "center",
  },
  loader: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  paymentButtonDisabled: {
    backgroundColor: "#B0B0B0", // Grey color for disabled state
    padding: 15,
    borderRadius: 10,
    marginVertical: 15, // Ensured sufficient gap
    alignSelf: "center",
    width: 200,
  },
  paymentDetail: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "80%",
    marginVertical: 8,
  },
  paymentLabel: {
    fontSize: 16,
    fontWeight: "bold",
  },
  paymentValue: {
    fontSize: 16,
  },
  buttonContainer: {
    backgroundColor: "#0B67BC",
    padding: 15,
    borderRadius: 10,
    alignSelf: "center",
    marginBottom: 20,
    width: "80%",
    maxWidth: 400,
  },
  phoneInputText: {
    flex: 1,
    color: "#FFFFFF",
    fontSize: 16,
    paddingLeft: 10,
  },
  UserInfoButtonContainer: {
    padding: 15,
    borderRadius: 10,
    alignSelf: "center",
    marginBottom: 20,
    width: "80%",
    maxWidth: 400,
  },
  termsContainer: {
    backgroundColor: "#212B36",
    padding: 20,
    borderRadius: 10,
    borderColor: "#26313D",
    borderWidth: 1,
    width: "90%",
    maxWidth: 600,
    alignSelf: "center",
    marginVertical: 20,
  },
  termsTitle: {
    fontSize: 22,
    fontWeight: "bold",
    color: "#FFFFFF",
    textAlign: "center",
    marginBottom: 20,
  },
  termsText: {
    color: "#FFFFFF", // Set text color to white
    fontSize: 16, // Adjust font size
    lineHeight: 24, // Increase line height for better readability
    padding: 10, // Add padding for spacing
  },
  markdownStyle: {
    body: {
      color: "#FFFFFF", // Set the main text color to white
      fontSize: 16,
      lineHeight: 24,
    },
    heading1: {
      color: "#FFFFFF", // Set header color to white
      fontSize: 24,
      marginBottom: 10,
    },
    link: {
      color: "#0B67BC", // Link color
      textDecorationLine: "underline",
    },
    paragraph: {
      marginBottom: 10, // Space between paragraphs
    },
  },
  errorText: {
    color: "#e74c3c",
    fontSize: 14,
    marginBottom: 10,
    textAlign: "center",
  },
  errorDialog: {
    marginHorizontal: 20, // Add some margin on the sides
    width: "80%", // Constrain the width of the dialog
    maxWidth: 400, // Maximum width for larger screens
    alignSelf: "center", // Center the dialog
    padding: 20, // Add padding inside the dialog
    borderRadius: 10, // Round the corners
    backgroundColor: "#212B36", // Background color to match the theme
  },
  errorDialogueText: {
    color: "#FFFFFF", 
    fontSize: 14,
    marginBottom: 10,
    textAlign: "center",
  },
});

export default styles;
