import React, { useEffect } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { getFirestore, collection, query, where, getDocs, doc, updateDoc } from 'firebase/firestore';
import { initializeApp, getApp, getApps } from 'firebase/app';

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD3BTQ236NOai-jxQk6KftOl0lNQSuMZZA",
  authDomain: "complinacecheck-us-app-e0521.firebaseapp.com",
  projectId: "complinacecheck-us-app-e0521",
  storageBucket: "complinacecheck-us-app-e0521.appspot.com",
  messagingSenderId: "539406454144",
  appId: "1:539406454144:web:92cdb75c81e6326fb50565",
  measurementId: "G-S1MR29C329"
};


// Ensure Firebase is initialized
const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();
const db = getFirestore(app);

const SuccessScreenSumSub = ({ route }) => {
  const { type, externalUserId, reviewResult } = route.params;

  useEffect(() => {
    const handleVerification = async () => {
      if (type === 'applicantReviewed' && reviewResult === 'GREEN') {
        try {
          const q = query(collection(db, 'Users'), where('externalUserId', '==', externalUserId));
          const querySnapshot = await getDocs(q);

          if (!querySnapshot.empty) {
            const userDoc = querySnapshot.docs[0];
            await updateDoc(doc(db, 'Users', userDoc.id), {
              verified: true,
            });
            console.log('User verification status updated successfully');
          } else {
            console.error('No user found with the given externalUserId');
          }
        } catch (error) {
          console.error('Error updating verification status:', error);
        }
      }
    };

    handleVerification();
  }, [type, externalUserId, reviewResult]);

  return (
    <View style={styles.container}>
      <Text style={styles.text}>Verification Status Updated</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    fontSize: 18,
    marginBottom: 20,
  },
});

export default SuccessScreenSumSub;