import React, { useState, useLayoutEffect } from 'react';
import { View, Text, TextInput, Button, ScrollView, TouchableOpacity, Image } from 'react-native';
import { signOut } from '@firebase/auth';
import { auth, db } from '../firebase/firebaseConfig';
import { doc, updateDoc } from '@firebase/firestore';
import styles from '../styles/styles';
import Loading from '../components/Loading'; // Importing Loading component

const AgreementScreen = ({ navigation }) => {
  const [privacyChecked, setPrivacyChecked] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [signature, setSignature] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: () => null,
      headerTitle: () => (
        <TouchableOpacity onPress={() => navigation.navigate('Home')} style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
          <Image source={require('../assets/icon.png')} style={styles.headerLogo} resizeMode="contain" />
          <Text style={styles.navBarText}>Return Home</Text>
        </TouchableOpacity>
      ),
      headerRight: () => (
        <Button
          onPress={handleLogout}
          title="Logout"
          color="#e74c3c"
          style={styles.headerRightButton}
        />
      ),
      headerStyle: {
        backgroundColor: '#161C24',
      },
      headerTintColor: '#fff',
    });
  }, [navigation]);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigation.navigate('Auth');
    } catch (error) {
      console.error('Logout error:', error.message);
    }
  };

  const handleComplete = async () => {
    if (!privacyChecked || !termsChecked || !signature) {
      alert('Please agree to both the Privacy Policy and the Terms and Conditions and provide your signature.');
      return;
    }

    setIsLoading(true);
    try {
      const user = auth.currentUser;
      if (user) {
        const docRef = doc(db, 'Users', user.uid);
        await updateDoc(docRef, {
          privacyPolicyAccepted: true,
          termsAccepted: true,
          signature: signature,
        });
        navigation.navigate('UserInfo');
      }
    } catch (error) {
      console.error('Error updating document:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <View style={{ flex: 1 }}>
      {isLoading && <Loading />}
      <ScrollView contentContainerStyle={styles.container}>
        <View style={styles.infoSection}>
          <Text style={styles.title}>KYC Data Submission Agreement</Text>
          <Text style={styles.description}>
            I hereby confirm that the information I have provided in this KYC (Know Your Customer) application is accurate,
            current, and complete to the best of my knowledge...
          </Text>
          <TouchableOpacity onPress={() => navigation.navigate('PrivacyPolicy')}>
            <Text style={styles.link}>Read Privacy Policy</Text>
          </TouchableOpacity>
          <View style={styles.checkboxContainer}>
            <Text style={styles.description}>Agree to Privacy Policy</Text>
            <TouchableOpacity onPress={() => setPrivacyChecked(!privacyChecked)}>
              <View style={styles.checkbox}>
                {privacyChecked && <View style={styles.checkboxChecked} />}
              </View>
            </TouchableOpacity>
          </View>
          <TouchableOpacity onPress={() => navigation.navigate('Terms')}>
            <Text style={styles.link}>Read Terms and Conditions</Text>
          </TouchableOpacity>
          <View style={styles.checkboxContainer}>
            <Text style={styles.description}>Agree to Terms and Conditions</Text>
            <TouchableOpacity onPress={() => setTermsChecked(!termsChecked)}>
              <View style={styles.checkbox}>
                {termsChecked && <View style={styles.checkboxChecked} />}
              </View>
            </TouchableOpacity>
          </View>
          <TextInput
            style={styles.input}
            value={signature}
            onChangeText={setSignature}
            placeholder="Enter your full legal name as a signature"
            placeholderTextColor="#B0B0B0"
          />
          <TouchableOpacity style={styles.button} onPress={handleComplete} disabled={isLoading}>
            <Text style={styles.buttonText}>I Agree</Text>
          </TouchableOpacity>
        </View>
      </ScrollView>
      <View style={styles.footer}>
        <Image source={require('../assets/xgc_logo@0.75x.png')} style={styles.footerLogo} />
      </View>
    </View>
  );
};

export default AgreementScreen;
