import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import AgreementScreen from '../screens/AgreementScreen';
import AuthScreen from '../screens/AuthScreen';
import HomeScreen from '../screens/HomeScreen';
import PrivacyPolicyScreen from '../screens/PrivacyPolicyScreen';
import SignatureScreen from '../screens/SignatureScreen';
import TermsScreen from '../screens/TermsScreen';
import UserInfoScreen from '../screens/UserInfoScreen';
import SumSubVerification from '../screens/SumSubVerification';
import SumSubVerificationComponent from '../screens/SumSubVerificationComponent';
import SuccessScreen from '../screens/SuccessScreen';
import SuccessScreenSumSub from '../screens/SuccessScreenSumSub';

const Stack = createStackNavigator();

const AppNavigation = () => {
  return (
    <Stack.Navigator initialRouteName="Home">
      {/* Define all your stack screens here */}
      <Stack.Screen name="Auth" component={AuthScreen} options={{ headerShown: true, title: "Authorization Screen"}} />
      <Stack.Screen name="Agreement" component={AgreementScreen} options={{ title: 'Agreement' }} />
      <Stack.Screen name="Home" component={HomeScreen} options={{ headerShown: false, title: 'Home'}} />
      <Stack.Screen name="PrivacyPolicy" component={PrivacyPolicyScreen} options={{ title: 'Privacy Policy' }} />
      <Stack.Screen name="Signature" component={SignatureScreen} options={{ title: 'Signature' }} />
      <Stack.Screen name="Terms" component={TermsScreen} options={{ title: 'Terms and Conditions' }} />
      <Stack.Screen name="UserInfo" component={UserInfoScreen} options={{ title: 'User Info' }} />
      <Stack.Screen name="SumSubVerification" component={SumSubVerification} options={{ title: 'Verification' }} />
      <Stack.Screen name="SumSubVerificationComponent" component={SumSubVerificationComponent} options={{ title: 'SumSubVerificationComponent' }} />
      <Stack.Screen name="SuccessScreen" component={SuccessScreen} options={{ title: 'SuccessScreen' }} />
      <Stack.Screen name="SuccessScreenSumSub" component={SuccessScreenSumSub} options={{ title: 'SuccessScreenSumSub' }} />
    </Stack.Navigator>
  );
};

export default AppNavigation;
