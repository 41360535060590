import React, { useEffect, useLayoutEffect } from 'react';
import { View, Text, Alert, Button, Image } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { auth, db } from '../firebase/firebaseConfig';
import { doc, updateDoc } from 'firebase/firestore';

const SuccessScreen = ({ route }) => {
  const navigation = useNavigation();

  const handleLogout = () => {
    auth.signOut()
      .then(() => {
        navigation.navigate('Login');
      })
      .catch((error) => {
        Alert.alert('Error', 'Failed to log out.');
        console.error('Error logging out:', error);
      });
  };

  useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: () => null, // Remove the back button
      headerTitle: () => (
        <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
          <Image source={require('../assets/icon.png')} style={{ width: 200, height: 200 }} resizeMode="contain" />
        </View>
      ),
      headerRight: () => (
        <Button
          onPress={handleLogout}
          title="Logout"
          color="#e74c3c"
        />
      ),
      headerStyle: {
        backgroundColor: '#161C24',
      },
      headerTintColor: '#fff',
    });
  }, [navigation]);

  useEffect(() => {
    const handlePaymentSuccess = async () => {
      const { sessionId } = route.params;

      try {
        // Wait for Firebase Authentication to initialize
        await new Promise((resolve) => {
          const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
              resolve(user);
            }
          });
          return unsubscribe;
        });

        // Check if currentUser is defined
        if (!auth.currentUser) {
          throw new Error('User not authenticated.');
        }

        // Update Firestore with paid status
        const userRef = doc(db, 'Users', auth.currentUser.uid);
        await updateDoc(userRef, { paid: true });

        // Optionally, perform any other actions upon successful payment

        // Wait for 5 seconds before navigating
        setTimeout(() => {
          // Navigate back to UserInfoScreen or any other desired screen
          navigation.navigate('UserInfo');
        }, 5000);
      } catch (error) {
        console.error('Error updating paid status:', error);
        Alert.alert('Error', 'Failed to update payment status.');
      }
    };

    handlePaymentSuccess();
  }, [navigation, route.params]);

  return (
    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: '#363636' }}>
      <Image source={require('../assets/done.gif')} style={{ width: 200, height: 200 }} />
      <Text style={{ color: '#fff', marginTop: 20 }}>Payment Success!</Text>
    </View>
  );
};

export default SuccessScreen;
