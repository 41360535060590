import React, { useEffect, useRef } from 'react';

const SumSubVerificationComponent = ({ accessToken, applicantEmail, applicantPhone, applicantFirstName, applicantLastName, theme }) => {
  const sumsubContainerRef = useRef(null);

  useEffect(() => {
    const launchWebSdk = async () => {
      try {
        const snsWebSdk = await import('@sumsub/websdk');
        const instance = snsWebSdk.default
          .init(accessToken, () => Promise.resolve(accessToken))
          .withConf({
            lang: 'en',
            email: applicantEmail,
            phone: applicantPhone,
            theme: theme,  // Ensure theme is passed as a string
            fixedInfo: {              
              firstName: applicantFirstName,
              lastName: applicantLastName
            }
          })
          .withOptions({ addViewportTag: false, adaptIframeHeight: true })
          .on('idCheck.onStepCompleted', (payload) => {
            console.log('onStepCompleted', payload);
          })
          .on('idCheck.onError', (error) => {
            console.log('onError', error);
          })
          .build();
        
        instance.launch(sumsubContainerRef.current);
      } catch (error) {
        console.error('Error initializing SumSub:', error);
      }
    };

    launchWebSdk();
  }, [accessToken, applicantEmail, applicantPhone, applicantFirstName, applicantLastName, theme]);

  return (
    <div ref={sumsubContainerRef} id="sumsub-websdk-container" style={{ width: '100%', height: '100vh' }}>
      Loading SumSub Verification...
    </div>
  );
};

export default SumSubVerificationComponent;
