import React, { useEffect, useState } from 'react';

const SumSubVerification = ({ route }) => {
  const { accessToken, applicantId } = route.params;
  const [uri, setUri] = useState(null);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const htmlContent = `
        <!DOCTYPE html>
        <html lang="en">
        <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <meta http-equiv="Feature-Policy" content="camera 'self'; microphone 'self'">
          <meta http-equiv="Permissions-Policy" content="camera=(self 'https://static.sumsub.com'); microphone=()">
          <title>SumSub Verification</title>
        </head>
        <body>
          <div id="sumsub-websdk-container"></div>
          <script src="https://static.sumsub.com/idensic/static/sns-websdk-builder.js"></script>
          <script>
            document.addEventListener("DOMContentLoaded", function() {
              function launchWebSdk(accessToken, applicantId) {
                let snsWebSdkInstance = snsWebSdk
                    .init(accessToken, () => Promise.resolve(accessToken))
                    .withConf({
                        lang: "en",
                        applicantId: applicantId
                    })
                    .withOptions({ addViewportTag: false, adaptIframeHeight: true })
                    .on("idCheck.onStepCompleted", (payload) => {
                        console.log("onStepCompleted", payload);
                    })
                    .on("idCheck.onError", (error) => {
                        console.log("onError", error);
                    })
                    .build();
                snsWebSdkInstance.launch("#sumsub-websdk-container");
              }
              launchWebSdk("${accessToken}", "${applicantId}");
            });
          </script>
        </body>
        </html>
      `;
      setUri(`data:text/html;base64,${btoa(htmlContent)}`);
    }
  }, [accessToken, applicantId]);

  if (!uri) {
    return <div>Loading...</div>;
  }

  return (
    <iframe
      src={uri}
      title="SumSub Verification"
      style={{ width: '100%', height: '100vh', border: 'none' }}
    />
  );
};

export default SumSubVerification;