import React, { useEffect, useState } from 'react';
import { ScrollView, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import Markdown from 'react-native-markdown-display';
import styles from '../styles/styles';
import Loading from '../components/Loading'; // Importing Loading component
import termsConditions from '../documents/terms_conditions.md';

const TermsScreen = () => {
  const [content, setContent] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const navigation = useNavigation();

  useEffect(() => {
    fetch(termsConditions)
      .then(response => response.text())
      .then(text => {
        setContent(text);
        setIsLoading(false); // Set loading to false after content is loaded
      });
  }, []);

  const handleLinkPress = (url) => {
    if (url === 'privacy_policy') {
      navigation.navigate('PrivacyPolicy');
      return false;
    }
    return true;
  };

  return (
    <View style={{ flex: 1, backgroundColor: '#161C24' }}>
      {isLoading && <Loading />}
      <ScrollView contentContainerStyle={styles.scrollViewContent}>
        <Markdown style={styles.markdownStyle} onLinkPress={handleLinkPress}>
          {content}
        </Markdown>
      </ScrollView>
    </View>
  );
};

export default TermsScreen;
