import React from 'react';
import { View, Text, TouchableOpacity, Image } from 'react-native';
import styles from '../styles/styles';

const HomeScreen = ({ navigation }) => {
  return (
    <View style={styles.container}>
      <Text style={styles.title}>Welcome to ComplianceCheck.us</Text>
      <Text style={styles.subtitle}>Private KYC Processing</Text>
      <Text style={styles.description}>
        We streamline the KYC process, making it easier for businesses to verify the identity of their clients quickly and efficiently.
      </Text>
      <TouchableOpacity
        onPress={() => navigation.navigate('Auth')}
        style={styles.linkContainer}
      >
        <Text style={styles.link}>Already have an account? Sign In</Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={styles.button}
        onPress={() => navigation.navigate('Auth', { mode: 'signUp' })}
      >
        <Text style={styles.buttonText}>Get Started</Text>
      </TouchableOpacity>
      <Text style={styles.title}>How It Works</Text>
      <View style={styles.infoSection}>
        <Text style={styles.infoTitle}>1. Sign Up</Text>
        <Text style={styles.infoText}>1.1 Create an account to get started with your KYC compliance journey.</Text>
        <Text style={styles.infoText}>1.2 Provide your details.</Text>
        <Text style={styles.infoTitle}>2. Submit Documents</Text>
        <Text style={styles.infoText}>2.1 Download the Compliance KYC PDF, complete it, and sign it.</Text>
        <Text style={styles.infoText}>2.2 Upload the necessary documents for identity verification through our secure platform.</Text>
        <Text style={styles.infoText}>2.3 Pay non-refundable deposit service fee for KYC Processing.</Text>
        <Text style={styles.infoTitle}>3. Verification</Text>
        <Text style={styles.infoText}>3.1 Our team of experts will review the documents and verify your identity.</Text>
        <Text style={styles.infoText}>3.2 If any additional information is required, our team will contact you.</Text>
        <Text style={styles.infoTitle}>4. Compliance</Text>
        <Text style={styles.infoText}>4.1 Once verified, you'll receive a compliance approval, ensuring that you meet all regulatory requirements.</Text>
        <Text style={styles.infoText}>4.2 If approved your brokerage account will be opened.</Text>
        <Text style={styles.infoTitle}>5. Brokerage Account</Text>
        <Text style={styles.infoText}>5.1 You will be requested to provide DSRs and/or Stock Certificates. These will be uploaded in this app.</Text>
        <Text style={styles.infoText}>5.2 Your personal broker information will be provided in the app.</Text>
      </View>
      <Image
        source={require('../assets/xgc_logo@0.75x.png')}
        style={styles.footerLogo}
      />
    </View>
  );
};

export default HomeScreen;
