import React, { useEffect, useRef } from 'react';
import { Linking } from 'react-native';
import { NavigationContainer, useNavigation } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import SuccessScreen from './screens/SuccessScreen'; // Assuming you have a SuccessScreen component
import HomeScreen from './screens/HomeScreen'; // Assuming you have a HomeScreen component
import AppNavigation from './navigation/navigation';
import SuccessScreenSumSub from './screens/SuccessScreenSumSub'; // Import SuccessScreenSumSub

const Stack = createStackNavigator();

const App = () => {
  const navigationRef = useRef();

  useEffect(() => {
    const handleDeepLink = ({ url }) => {
      console.log('Received URL:', url);

      // Extract route and parameters from URL
      const route = url.split('?')[0];
      const params = new URLSearchParams(url.split('?')[1]);
      console.log('Extracted route:', route);

      // Navigate based on the extracted route
      if (route.endsWith('SuccessScreen')) {
        console.log('Navigating to SuccessScreen');
        const sessionId = params.get('sessionId');
        navigationRef.current?.navigate('SuccessScreen', { sessionId });
      } else if (route.endsWith('SuccessScreenSumSub')) {
        console.log('Navigating to SuccessScreenSumSub');
        const type = params.get('type');
        const applicantId = params.get('applicantId');
        const reviewResult = {
          reviewAnswer: params.get('reviewAnswer'),
        };
        navigationRef.current?.navigate('SuccessScreenSumSub', { type, applicantId, reviewResult });
      } else {
        console.log('No matching route, staying on HomeScreen');
      }
    };

    // Get the initial URL if the app was opened via a deep link
    Linking.getInitialURL().then((url) => {
      if (url) {
        handleDeepLink({ url });
      }
    });

    // Add event listener for deep links
    const linkingListener = Linking.addEventListener('url', handleDeepLink);

    // Clean up event listener
    return () => {
      linkingListener.remove();
    };
  }, []);

  return (
    <NavigationContainer ref={navigationRef}>
      <AppNavigation />
    </NavigationContainer>
  );
};

export default App;
