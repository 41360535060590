import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import styles from '../styles/styles';

const SignatureScreen = () => {
  return (
    <View style={styles.container}>
      <Text style={styles.title}>Signature Page</Text>
      <Text style={styles.text}>Your signature component goes here...</Text>
    </View>
  );
};

export default SignatureScreen;