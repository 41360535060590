import React, { useEffect, useState, useLayoutEffect } from 'react';
import { View, Text, Button, ActivityIndicator, Alert, Switch, TouchableOpacity, Image } from 'react-native';
import { Linking } from 'react-native';
import axios from 'axios';
import { auth, db } from '../firebase/firebaseConfig';
import { doc, setDoc, onSnapshot, getDoc } from 'firebase/firestore';
import SumSubVerificationComponent from './SumSubVerificationComponent'; // Import SumSubVerificationComponent
import styles from '../styles/styles'; // Importing styles from the external stylesheet
import RadioButtonGroup, { RadioButtonItem } from 'expo-radio-button'; // Importing RadioButton component from a library
import AsyncStorage from '@react-native-async-storage/async-storage'; // Import AsyncStorage
import { REACT_NATIVE_SUMSUB_API_PROXY_DEV, REACT_NATIVE_SUMSUB_API_PROXY_PROD } from '@env';

const UserInfoScreen = ({ navigation }) => {
  const [accessToken, setAccessToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({
    email: '',
    mobile: '',
    firstName: '',
    surname: '',
    signature: '',
    verified: false,
    paid: false,
    verificationStarted: false,
    applicantId: '', // Added applicantId
  });
  const [verificationType, setVerificationType] = useState('individual'); // State for verification type (individual or business)

  useEffect(() => {
    const userId = auth.currentUser.uid;
    const userDocRef = doc(db, 'Users', userId);

    const unsubscribe = onSnapshot(userDocRef, (doc) => {
      if (doc.exists()) {
        const data = doc.data();
        setUserData({
          email: data.email || '',
          mobile: data.mobile || '',
          firstName: data.firstName || '',
          surname: data.surname || '',
          signature: data.signature || '',
          verified: data.verified || false,
          paid: data.paid || false,
          verificationStarted: data.verification_started || false, // Fetch verification_started field
          applicantId: data.applicantId || '', // Fetch applicantId
        });
      } else {
        console.log('Cannot find user document!');
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    // Retrieve verificationType from AsyncStorage on component mount
    const getVerificationType = async () => {
      try {
        const storedVerificationType = await AsyncStorage.getItem('verificationType');
        if (storedVerificationType) {
          setVerificationType(storedVerificationType);
        }
      } catch (error) {
        console.error('Failed to retrieve verificationType:', error);
      }
    };
    
    getVerificationType();
  }, []);

  useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: () => null, // Remove the back button
      headerTitle: () => (
        <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
          <Image source={require('../assets/icon.png')} style={{ width: 200, height: 200 }} resizeMode="contain" />
        </View>
      ),
      headerRight: () => (
        <Button
          onPress={handleLogout}
          title="Logout"
          color="#e74c3c"
        />
      ),
      headerStyle: {
        backgroundColor: '#161C24',
      },
      headerTintColor: '#fff',
    });
  }, [navigation]);

  const handleVerificationTypeChange = async (value) => {
    console.log('Verification type changed to:', value);
    setVerificationType(value);
    setAccessToken(null); // Reset accessToken

    // Persist the new verificationType in AsyncStorage
    try {
      await AsyncStorage.setItem('verificationType', value);
    } catch (error) {
      console.error('Failed to save verificationType:', error);
    }
  };

  const handleStartOrContinueVerification = async () => {
    setLoading(true);
    try {
      const userId = auth.currentUser.uid;
      const email = auth.currentUser.email;
      const userIdWithoutDomain = email.split('@')[0];
  
      // Determine the prefix based on verification type
      const prefix = verificationType === 'individual' ? '-i' : '-b';
      const prefixedUserId = `${userIdWithoutDomain}${prefix}`;
  
      // Update Firebase user document with the prefixed userId
      const userDocRef = doc(db, 'Users', userId);
      await setDoc(userDocRef, { externalUserId: prefixedUserId, verification_started: true }, { merge: true });
  
      // Determine the level name based on verification type
      const levelName = verificationType === 'individual' 
        ? 'XGC_Basic_KYC_01'
        : 'XGC_CorporateOnboarding101';

      // Set the cloud function URL based on the environment
      const cloudFunctionUrl = __DEV__ 
        ? REACT_NATIVE_SUMSUB_API_PROXY_DEV
        : REACT_NATIVE_SUMSUB_API_PROXY_PROD;
  
      // Request access token for verification
      const accessTokenUrl = `${cloudFunctionUrl}/resources/accessTokens?userId=${prefixedUserId}&levelName=${levelName}`;
      const accessTokenResponse = await axios.post(accessTokenUrl);
      const { token } = accessTokenResponse.data; // Ensure token is returned
      setAccessToken(token);
  
      // Wait for 5 seconds
      await new Promise(resolve => setTimeout(resolve, 15000));
  
      // Fetch updated user data
      const updatedUserDocRef = doc(db, 'Users', userId);
      const updatedUserDocSnap = await getDoc(updatedUserDocRef); // Use getDoc
      if (updatedUserDocSnap.exists()) {
        const updatedData = updatedUserDocSnap.data();
        setUserData(prev => ({
          ...prev,
          firstName: updatedData.firstName || '',
          surname: updatedData.surname || '',
          applicantId: updatedData.applicantId || '' // Include applicantId if stored in Firestore
        }));
        
        // Trigger update applicant part
        if (updatedData.applicantId) {
          await axios.patch(`${cloudFunctionUrl}/resources/applicants/${updatedData.applicantId}/fixedInfo`, {
            firstName: updatedData.firstName,
            lastName: updatedData.surname, // Ensure field names match your API requirements
          });
          console.log('Applicant updated successfully.');
        } else {
          console.log('No applicantId found to update.');
        }
      }
    } catch (error) {
      console.error('Error starting verification:', error.response ? error.response.data : error.message);
      Alert.alert('Error', 'Failed to start verification process. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigation.navigate('Auth');
    } catch (error) {
      console.error('Logout error:', error.message);
    }
  };

  // const handleCheckout = async () => {
  //   const stripePaymentUrl = `https://buy.stripe.com/test_9AQeXBaafeiI1nq3cc?prefilled_email=${encodeURIComponent(auth.currentUser.email)}&success_url=https://complinacecheck-us-app-e0521.web.app/SuccessScreen`;
  //   Linking.openURL(stripePaymentUrl).catch((err) => {
  //     console.error('Error opening Stripe payment URL:', err);
  //     Alert.alert('Error', 'Failed to open the payment page. Please try again later.');
  //   });
  // };

  const handleCheckout = async () => {
    try {
      // Define URLs based on the environment
      const stripePaymentUrlDev = `https://buy.stripe.com/test_9AQeXBaafeiI1nq3cc?prefilled_email=${encodeURIComponent(auth.currentUser.email)}&success_url=https://dev.compliancecheck.us/SuccessScreen`;
      const stripePaymentUrlProd = `https://buy.stripe.com/fZe15x2b45kk6PK7ss?prefilled_email=${encodeURIComponent(auth.currentUser.email)}&success_url=https://app.compliancecheck.us/SuccessScreen`;
  
      // Verify environment
      console.log('Is this development mode?', __DEV__);
  
      // Choose the appropriate URL based on the environment
      const stripePaymentUrl = __DEV__ ? stripePaymentUrlDev : stripePaymentUrlProd;
  
      // Log the chosen URL
      console.log('Stripe Payment URL:', stripePaymentUrl);
  
      // Open the Stripe payment URL
      await Linking.openURL(stripePaymentUrl);
    } catch (err) {
      console.error('Error opening Stripe payment URL:', err);
      Alert.alert('Error', 'Failed to open the payment page. Please try again later.');
    }
  };


  return (
    <View style={styles.container}>
      <Text style={styles.title}>User Information</Text>
      <View style={styles.infoSection}>
        <Text style={styles.infoLine}>
          <Text style={styles.infoTitle}>Email:</Text> <Text style={styles.infoText}>{userData.email}</Text> {' '}
        </Text>
        <Text style={styles.infoLine}>
          <Text style={styles.infoTitle}>Mobile:</Text> <Text style={styles.infoText}>{userData.mobile}</Text> {' '}
        </Text>
        <Text style={styles.infoLine}>
          <Text style={styles.infoTitle}>Name:</Text> <Text style={styles.infoText}>{userData.firstName}{' '}{userData.surname}</Text> {' '}
        </Text>
        <Text style={styles.infoLine}>
          <View style={styles.checkboxContainer}>
            <Text style={styles.infoTitle}>KYC Approved:</Text>
            <Switch
              value={userData.verified}
              disabled={true}
              trackColor={{ false: "#D32F2F", true: "#8BC34A" }}
              thumbColor={userData.verified ? "#8BC34A" : "#B0B0B0"}
              style={styles.switch}
            />
          </View>
          <View style={styles.checkboxContainer}>
            <Text style={styles.infoTitle}>Paid:</Text>
            <Switch
              value={userData.paid}
              disabled={true}
              trackColor={{ false: "#D32F2F", true: "#8BC34A" }}
              thumbColor={userData.paid ? "#8BC34A" : "#B0B0B0"}
              style={styles.switch}
            />
          </View>
        </Text>
      </View>

      {/* Radio Button Verification Type */}
      <View style={styles.radioGroupContainer}>
        <Text style={styles.infoTitle}>Verification Type:</Text>
        <RadioButtonGroup
          selected={verificationType}
          onSelected={handleVerificationTypeChange}
          containerStyle={styles.radioGroup}
          radioBackground='#0B67BC' 
          labelStyle={styles.radioButtonLabel} 
        >
          <RadioButtonItem
            value='individual'
            label='Individual'
            style={styles.radioButton}
          />
          <RadioButtonItem
            value='business'
            label='Business'
            style={styles.radioButton}
          />
        </RadioButtonGroup>
      </View>

      <View style={styles.UserInfoButtonContainer}>
        <TouchableOpacity
          style={[styles.paymentButton, userData.paid ? styles.buttonDisabled : {}]}
          onPress={handleCheckout}
          disabled={userData.paid}
        >
          <Text style={styles.buttonText}>Make Payment</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={[styles.button, !userData.paid ? styles.buttonInactive : {}]}
          onPress={handleStartOrContinueVerification}
          disabled={!userData.paid} // Disable when not paid
        >
          <Text style={styles.buttonText}>Start or Continue Verification</Text>
        </TouchableOpacity>
      </View>
      {loading && <ActivityIndicator size="large" color="#0000ff" />}
      {accessToken && (
        <SumSubVerificationComponent accessToken={accessToken} verificationType={verificationType} />
      )}
    </View>
  );
};

export default UserInfoScreen;
